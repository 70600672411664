import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { change as reduxFormChange } from "redux-form";

import { useConfig } from "../../configProvider";
import { useFetchOnce } from "../../shared/useFetchOnce.hook";
import { errorToList } from "../../shared/components/error-list";
import { useDispatchCallback } from '../../store/useDispatchCallback';

import { useAuthTokenContext } from "../auth/AuthService";
import { IProject } from "../project/types";
import { projectApi, projectApiActionCreator } from "../calculation/ProjectApi.redux";
import { ICalculationFormsState } from "../calculation/CalculationModels";
import { getFormValuesDictionaryFromForms, getProjectProductsFromForms } from "../calculation/CalculationService";
import { getCalculationState, getForm } from "../calculation/calculation.selector";
import { ICalculationState } from "../calculation/CalculationReducer";
import { ReduxFormKeys } from "../form/ReduxFormKeys";

import { ReportingApiV1Client } from "./ReportingV1Api";


function useReporting(
    projectId: IProject['id'],
    calculationState: ICalculationState,
    form: ICalculationFormsState,
) {
    const { apiUrl } = useConfig();
    const { getToken } = useAuthTokenContext();
    const client = React.useMemo(() => new ReportingApiV1Client(apiUrl['reporting-v1'], getToken), [apiUrl, getToken]);

    const submitGetter = React.useCallback(() => {
        if (projectId == null || !calculationState || !form) return;

        return client.submit({
            id: projectId,
            products: getProjectProductsFromForms(form, projectId, calculationState),
            formValues: getFormValuesDictionaryFromForms(form),
            selectedProducts: calculationState.selectedProducts,
            selectedAIOBasicSet: calculationState.selectedAIOBasicSet,
            selectedNonStandardComponents: calculationState.nonStandardComponents,
        });
    }, [client, projectId, calculationState, form]);

    const {
        value: submittedProject,
        error: submitError,
        loading: submitLoading,
        fetch: submit,
        reset,
    } = useFetchOnce(submitGetter);

    return React.useMemo(() => ({
        submittedProject,
        submitError: errorToList(submitError),
        submitLoading,
        submit,
        reset,
    }), [
        submittedProject,
        submitError,
        submitLoading,
        submit,
        reset,
    ]);
}

/** Integrate Reporting API (see `useReporting()`) with Project and Calculation State in Redux */
export function useReportingWithRedux() {
    const projectId = useSelector(projectApi.makeSelectBase('entity'))?.id;
    const calculationState = useSelector(getCalculationState);
    const form = useSelector(getForm);

    const {
        submit: submitBase,
        submittedProject,
        ...others
    } = useReporting(projectId, calculationState, form);

    const dispatch = useDispatch();
    const dispatchReduxChange = useDispatchCallback(dispatch, reduxFormChange);
    const dispatchUpdateProject = useDispatchCallback(dispatch, projectApiActionCreator.updateSuccess);

    const updateRequired = React.useRef<boolean>(false);

    const submit = React.useCallback(async (...params: Parameters<typeof submitBase>) => {
        await submitBase(...params);
        updateRequired.current = true;
    }, [submitBase, dispatchUpdateProject]);

    React.useEffect(() => {
        if (!updateRequired.current) return;
        if (!submittedProject) return;

        dispatchReduxChange(ReduxFormKeys.costCalculationForm, 'projectState', submittedProject.state);
        dispatchUpdateProject(submittedProject);

        updateRequired.current = false;
    }, [submittedProject, updateRequired.current]);

    return {
        submit,
        ...others
    };
}
