/**
 * This file is in features/calculation instead of features/project
 * because it's legacy infrastructure only used by the legacy calculation feature.
 */
import { change } from "redux-form";
import { replace } from "connected-react-router";
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import autobind from "autobind-decorator";

import { configProvider } from "../../configProvider";
import { ReduxFormKeys } from "../form/ReduxFormKeys";
import { IApplicationState } from "../../shared/ApplicationState";
import { IFormErrorDict } from "../../shared/components/error-list";

import { IProject, PROJECT_STATE } from "../project/types";

import { LIFT_STATUS_DETECTION } from '../project/lookup';
import { PRODUCT_CATEGORY } from '../product/lookup';
import { IProductData } from "../product/types";
import { ICalculationFormsState, INonStandardComponent } from "./CalculationModels";
import { BasicInputsFormModel, ConfigurationInputsFormModel, VentilationComponentsFormModel, CostCalculationFormModel } from './models/FormModels';
import {
    getFormValues,
    getFormValuesDictionaryFromForms,
    getProjectProductsFromForms,
} from "./CalculationService";
import { calculationActionCreator  } from "./CalculationActions";
import { calculationTabs, createAction } from "./constant";

import { authTokenContext } from "../auth/AuthService";

import { ProjectApiV1Client } from "../project/ProjectApiV1Client";
import { ProductApiV1Client } from "../product/ProductApiV1Client";


export const getLocation = (state: any) => state.router.location.pathname;
export const getActiveTabIndex = (state) => state.calculationState.activeTabIndex;

export interface IProjectApiState {
    entity: IProject;
    productData: IProductData;
    productDataCompanyId: number;
    error?: Error|null;
    loading: boolean;

    isUpdating: boolean;
    validationErrorList: IFormErrorDict;
}

const initialState: IProjectApiState = {
    entity: null,
    productData: null,
    productDataCompanyId: null,
    error: null,
    loading: false,

    isUpdating: false,
    validationErrorList: {},
};

enum ProjectActionTypes {
    GET_REQUEST = 'GET_REQUEST',
    GET_SUCCESS = 'GET_SUCCESS',
    GET_ERROR = 'GET_ERROR',

    MAKE_PROJECT = 'MAKE_PROJECT',
    MAKE_PROJECT_INJECT_PRODUCT_DATA = 'MAKE_PROJECT_INJECT_PRODUCT_DATA', // Internal, used my MAKE_PROJECT to inject sideloaded product data into state
    MAKE_PROJECT_SUCCESS = 'MAKE_PROJECT_SUCCESS',

    UPDATE_REQUEST = 'UPDATE_REQUEST',
    UPDATE_PENDING = 'UPDATE_PENDING',
    UPDATE_SUCCESS = 'UPDATE_SUCCESS',
    UPDATE_ERROR = 'UPDATE_ERROR',
}

interface IActionCallback {
    success: () => void;
    error: (err: any) => void;
}

@autobind
class ProjectApiActionCreator {

    constructor(private prefix: string) {
    }

    updateRequest(projectId: string, projectState?: PROJECT_STATE, sendMail?: boolean, callback?: IActionCallback) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.UPDATE_REQUEST,
            payload: {
                projectId,
                projectState,
                sendMail,
                callback,
            },
        });
    }

    updateSuccess(project: IProject) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.UPDATE_SUCCESS,
            payload: project,
        });
    }

    updatePending(projectId: string) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.UPDATE_PENDING,
            payload: {
                projectId,
            },
        });
    }

    updateError(errorDict: IFormErrorDict) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.UPDATE_ERROR,
            payload: errorDict,
        });
    }

    getRequest(projectId: string, loadAsTemplate: boolean) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.GET_REQUEST,
            payload: {
                projectId,
                loadAsTemplate,
            },
        });
    }

    getSuccess(project: IProject, loadAsTemplate: boolean, productData: IProductData) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.GET_SUCCESS,
            payload: {
                project,
                loadAsTemplate,
                productData,
            }
        });
    }

    getError(error: Error) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.GET_ERROR,
            payload: error,
        });
    }

    makeProject(companyId: number) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.MAKE_PROJECT,
            payload: {
                companyId,
            },
        });
    }

    makeProjectInjectProductData(companyId: number, productData: IProductData) {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.MAKE_PROJECT_INJECT_PRODUCT_DATA,
            payload: {
                companyId,
                productData,
            },
        });
    }

    makeProjectSuccess() {
        return createAction(this.prefix, <const>{
            meta: ProjectActionTypes.MAKE_PROJECT_SUCCESS,
        });
    }
}

function makeNewFormValues() {
    return {
        basicInputsForm: {
            values: {
                ...new BasicInputsFormModel(),
                noOfElevators: 1,
            }
        },
        configurationInputsForm: {
            values: {
                ...new ConfigurationInputsFormModel(),
                liftStatusDetection: LIFT_STATUS_DETECTION.LST_CO2_V3,
                'rt-45-rj': 1,
            }
        },
        ventilationComponentsForm: {
            values: new VentilationComponentsFormModel(),
        },
        costCalculationForm: {
            values: new CostCalculationFormModel(),
        }
    };
}

class ProjectApi {
    private readonly projectApiClient: ProjectApiV1Client;
    private readonly productApiClient: ProductApiV1Client;

    constructor(
        private readonly APP_NAME: any,
        projectApiV1BaseUrl: string,
        productApiV1BaseUrl: string,
        getToken: () => Promise<string|undefined>,
    ) {
        this.projectApiClient = new ProjectApiV1Client(projectApiV1BaseUrl, getToken);
        this.productApiClient = new ProductApiV1Client(productApiV1BaseUrl, getToken);
    }

    @autobind
    reducer(state = initialState, action: KnownProjectApiActions): IProjectApiState {
        if (action.type.startsWith(this.APP_NAME + "/")) {
            switch (action.meta) {
                case ProjectActionTypes.GET_ERROR: {
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                    };
                }
                case ProjectActionTypes.GET_SUCCESS: {
                    const {project, loadAsTemplate, productData} = action.payload;

                    if (loadAsTemplate) {
                        project.id = null;
                        project.state = PROJECT_STATE.NEW;
                    }

                    return {
                        ...state,
                        entity: project,
                        productData,
                        productDataCompanyId: project.owningCompanyId,
                        error: null,
                        loading: false,
                    };
                }
                case ProjectActionTypes.MAKE_PROJECT: {
                    return {
                        ...initialState, // Reset the entity to prevent leakage of old state
                        loading: true,
                    };
                }
                case ProjectActionTypes.MAKE_PROJECT_INJECT_PRODUCT_DATA: {
                    const { productData, companyId } = action.payload;
                    return {
                        ...state,
                        productData,
                        productDataCompanyId: companyId,
                    };
                }
                case ProjectActionTypes.MAKE_PROJECT_SUCCESS: {
                    return {
                        ...state,
                        loading: false,
                        error: null,
                    }
                }
                case ProjectActionTypes.UPDATE_PENDING: {
                    return {
                        ...state,
                        isUpdating: true,
                    };
                }
                case ProjectActionTypes.UPDATE_SUCCESS: {
                    return {
                        ...state,
                        entity: action.payload,
                        isUpdating: false,
                    };
                }
                case ProjectActionTypes.UPDATE_ERROR: {
                    return {
                        ...state,
                        validationErrorList: action.payload,
                        isUpdating: false,
                    };
                }
                default:
                    return state;
            }
        }
        return state;
    }

    @autobind
    * get(action: ReturnType<typeof projectApiActionCreator.getRequest>) {
        try {
            // Get the project and the product data for its owning company
            const project: IProject = yield call([this.projectApiClient, this.projectApiClient.getProjectById], action.payload.projectId);
            const productData: IProductData = yield call([this.productApiClient, this.productApiClient.getProductData], project.owningCompanyId);

            // Update state with success
            yield put(projectApiActionCreator.getSuccess(project, action.payload.loadAsTemplate, productData));

            // Restore non-standard-components list from products in PRODUCT_CATEGORY.NONSTANDARDCOMPONENTS
            const nonStandardComponentList = project.products
                .filter(x => x.productCategoryId === PRODUCT_CATEGORY.NONSTANDARDCOMPONENTS) as INonStandardComponent[];
            yield put(calculationActionCreator.setNonStandardComponentList(nonStandardComponentList));

            // Create form state objects from project
            const forms: ICalculationFormsState = getFormValues(project.formValues);

            // Set form values for quantities for products in PRODUCT_CATEGORY.VENTCOMP
            project.products.filter(x => x.productCategoryId === PRODUCT_CATEGORY.VENTCOMP).map(x =>
                forms.ventilationComponentsForm.values['quantity-' + x.productId] = x.quantity
            );

            // projectState is treated like a form value, but project.state is the canonical source of truth
            forms.costCalculationForm.values.projectState = project.state;

            // Initialize the forms
            yield put(calculationActionCreator.initializeForm(forms, productData));
        } catch (err) {
            yield put(projectApiActionCreator.getError(err));
            console.error(err);
        }
    }

    @autobind
    * makeProject(action: ReturnType<typeof projectApiActionCreator.makeProject>) {
        const { companyId } = action.payload;
        try {
            const productData: IProductData = yield call([this.productApiClient, this.productApiClient.getProductData], companyId);
            yield put(projectApiActionCreator.makeProjectInjectProductData(companyId, productData));
            
            const forms = makeNewFormValues();
            yield put(calculationActionCreator.initializeForm(forms, productData));

            yield put(projectApiActionCreator.makeProjectSuccess());

        } catch (err) {
            yield put(projectApiActionCreator.getError(err));
            console.error(err);
        }
    }

    @autobind
    * update(action: ReturnType<typeof projectApiActionCreator.updateRequest>) {
        const state = yield select();

        const {projectId, projectState, sendMail} = action.payload;

        yield put(projectApiActionCreator.updatePending(projectId));

        try {

            const requestObj: IProject = {
                id: projectId,
                products: getProjectProductsFromForms(state.form, projectId, state.calculationState),
                formValues: getFormValuesDictionaryFromForms(state.form),
                state: projectState,
            } as any; // @TODO: Fix this any

            let result: IProject;
            try {
                result = yield call([this.projectApiClient, this.projectApiClient.saveProject], requestObj, sendMail);
            } catch (e) {
                yield put(projectApiActionCreator.updateError(e));
                action.payload.callback?.error(e);
                return;
            }

            yield put(change(ReduxFormKeys.costCalculationForm, 'projectState', result.state));
            yield put(projectApiActionCreator.updateSuccess(result));

            const activeTabIndex = yield select(getActiveTabIndex);

            const location = yield select(getLocation);

            const newRouteForm = calculationTabs[activeTabIndex];
            const route = '/calculation/' + result.id + '/' + newRouteForm;

            if (location === route) {
                console.log("SAME SAGA -->", route);
                return;
            }

            yield put(replace(route));
            console.log("REPLACE ROUTE FROM SAGA -->", route);
        } catch (err) {
            console.error(err);
            // yield put(this.listError(err));
        }
    }

    @autobind
    * saga() {
        yield all([
            takeLatest(this.APP_NAME + "/" + ProjectActionTypes.GET_REQUEST, this.get),
            takeLatest(this.APP_NAME + "/" + ProjectActionTypes.MAKE_PROJECT, this.makeProject),
            takeLatest(this.APP_NAME + "/" + ProjectActionTypes.UPDATE_REQUEST, this.update),
        ]);
    }

    @autobind
    makeSelectBase<T extends keyof IProjectApiState>(key: T): (state: IApplicationState) => IProjectApiState[T] {
        return (state) => state[this.APP_NAME][key];
    }

    @autobind
    selector(): (state: IApplicationState) => IProjectApiState {
        return (state) => state[this.APP_NAME];
    }
}

type ValueOf<T> = T[keyof T];
type KnownProjectApiActions = ReturnType<ValueOf<ProjectApiActionCreator>>;

const { apiUrl } = configProvider;
const { getToken } = authTokenContext;

const PROJECT_API_PREFIX = 'project';
export const projectApi = new ProjectApi(PROJECT_API_PREFIX, apiUrl['project-v1'], apiUrl['product-v1'], getToken);
export const projectApiActionCreator = new ProjectApiActionCreator(PROJECT_API_PREFIX);
