import { SimpleValidator, isArrayOf, isNumber, isObjectWithShape, isString } from "../api/simple-validators";

import { ICompany, IUser } from "./types";
import { UserGroup } from "./PermissionService";


export const isICompany: SimpleValidator<ICompany> = isObjectWithShape({
    id: isNumber,
    name: isString,
});

export const isIUser: SimpleValidator<IUser> = isObjectWithShape({
    id: isString,
    firstName: isString,
    lastName: isString,
    email: isString,
    company: isICompany,
    country: isString,
    groups: isArrayOf(isString as SimpleValidator<UserGroup>),
});
