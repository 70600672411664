import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { useConfig } from '../../configProvider';
import { useFetchOnce } from '../../shared/useFetchOnce.hook';
import { useDispatchCallback } from '../../store/useDispatchCallback';

import {
    projectApi,
    projectApiActionCreator,
} from "./ProjectApi.redux";
import { useAuthTokenContext } from '../auth/AuthService';

import { CalculationApiV1Client } from './CalculationApiV1Client';
import { ICalculationInput } from './CalculationModels';


/** Use Project State in Redux */
export function useProjectV1() {
    const dispatch = useDispatch();

    const {
        productData: productDataList,
        entity: project,
        error: projectError,
        loading: projectLoading,
        isUpdating: projectIsUpdating,
    } = useSelector(projectApi.selector());

    const getProject = useDispatchCallback(dispatch, projectApiActionCreator.getRequest);
    const makeProject = useDispatchCallback(dispatch, projectApiActionCreator.makeProject);
    const updateProject = useDispatchCallback(dispatch, projectApiActionCreator.updateRequest);

    return React.useMemo(() => ({
        productDataList,
        project,
        projectError,
        projectLoading,
        projectIsUpdating,
        getProject,
        makeProject,
        updateProject,
    }), [
        productDataList,
        project,
        projectError,
        projectLoading,
        projectIsUpdating,
        getProject,
        makeProject,
        updateProject,
    ]);
}

export function useCalculateCosts(calculationInput: ICalculationInput) {
    const { apiUrl } = useConfig();
    const { getToken } = useAuthTokenContext();
    const client = React.useMemo(() => new CalculationApiV1Client(apiUrl['calculation-v1'], getToken), [apiUrl, getToken]);

    const calculationGetter = React.useCallback(async () => {
        if (calculationInput == null) return null;
        return client.calculateCosts(calculationInput);
    }, [client, calculationInput]);

    const {
        value: costs,
        error: costsError,
        loading: costsLoading,
        fetch: fetchCosts,
        reset: resetCosts,
    } = useFetchOnce(calculationGetter);

    return React.useMemo(() => ({
        costs,
        costsError,
        costsLoading,
        fetchCosts,
        resetCosts,
    }), [
        costs,
        costsError,
        costsLoading,
        fetchCosts,
        resetCosts,
    ]);
}
