import { isNumber, isObjectWithShape, isString, SimpleValidator } from "../api/simple-validators";

import { ISettings } from "./types";


export const isISettings: SimpleValidator<ISettings> = isObjectWithShape({
    generalRemarks: isString,
    offerValidityWeeks: isNumber,
    deliveryWorkdays: isNumber,
});
