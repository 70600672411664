import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { ICompany } from "../auth/types";

import { IProductData } from "./types";
import { isIProductData } from "./validators";


/** @see `DH.BlueKitConnect.Backend.Features.Product.ProductApiV1Controller` */
export class ProductApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async getProductData(companyId: ICompany['id']): Promise<IProductData> {
        return await this._fetchJson(
            this._getUrl(['product-data'], [['companyId', companyId]]),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            isIProductData
        );
    }

}
