import { isArrayOf, isBoolean, isBoth, isNumber, isObjectWithShape, isOptional, isString, isTuple, isUnknown, SimpleValidator } from "../api/simple-validators";
import { IUser } from "../auth/types";
import { isICompany, isIUser } from "../auth/validators";
import { IProjectProduct } from "../calculation/CalculationModels";

import { GetProjectsResponse, IEnrichedProject, IFile, IFormValue, IProject, IProjectSynopsis, PROJECT_STATE } from "./types";


export const isIProjectProduct: SimpleValidator<IProjectProduct> = isObjectWithShape({
    projectId: isString,
    productId: isString,
    productCategoryId: isString,
    price: isOptional(isNumber),
    quantity: isNumber,
    description: isOptional(isString),
});

export const isIFormValue: SimpleValidator<IFormValue> = isObjectWithShape({
    key: isString,
    value: isUnknown,
});

export const isIFile: SimpleValidator<IFile> = isObjectWithShape({
    id: isString,
    name: isString,
    path: isString,
});

export const isIProjectSynopsis: SimpleValidator<IProjectSynopsis> = isObjectWithShape({
    id: isString,
    name: isString,
    state: isString as SimpleValidator<PROJECT_STATE>,
    createdAt: isString,
    createdBy: isUnknown as SimpleValidator<IUser>,
    owningCompany: isOptional(isICompany),
});

export const isGetProjectsReponse: SimpleValidator<GetProjectsResponse> = isTuple([
    isArrayOf(isIProjectSynopsis),
    isBoolean
] as [SimpleValidator<IProjectSynopsis[]>, SimpleValidator<boolean>]);

export const isIProject: SimpleValidator<IProject> = isBoth(isIProjectSynopsis, isObjectWithShape({
    createdById: isString,
    owningCompanyId: isNumber,
    lastReportingAt: isOptional(isString),

    products: isArrayOf(isIProjectProduct),
    formValues: isArrayOf(isIFormValue),
    files: isArrayOf(isIFile),
}));

export const isIEnrichedProject: SimpleValidator<IEnrichedProject> = isBoth(isIProject, isObjectWithShape({
    owningCompany: isICompany,
}));
