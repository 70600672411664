import { isObjectWithShape, isOptional, isStringEnum, isNoValue, isArrayOf, isString, isNumber, SimpleValidator } from "../api/simple-validators";

import { PRODUCT_KEY, PRODUCT_GROUP, PRODUCT_TYPE, PRODUCT_CATEGORY, CHARACTERISTIC_KEY } from "./lookup";
import { IProduct, IProductGroup, ICharacteristic, IProductData } from "./types";


export const isMultilanguageDescription: SimpleValidator<IProduct['description']> = isObjectWithShape({
    de: isString,
    en: isString,
});

export const isIProduct: SimpleValidator<IProduct> = isObjectWithShape({
    id: isString,
    productKey: isOptional(isString as SimpleValidator<PRODUCT_KEY>),
    description: isMultilanguageDescription,
    dhNo: isOptional(isString),
    bxH: isOptional(isString),
    width: isOptional(isNumber),
    height: isOptional(isNumber),
    ventilationArea: isOptional(isNumber),
    recess: isOptional(isString),
    productGroupId: isStringEnum(...Object.values(PRODUCT_GROUP)),
    productTypeId: isStringEnum(...Object.values(PRODUCT_TYPE)),
    imageUrl: isOptional(isString),
    isRecommended: isNoValue,
});

export const isIProductGroup: SimpleValidator<IProductGroup> = isObjectWithShape({
    id: isStringEnum(...Object.values(PRODUCT_GROUP)),
    description: isMultilanguageDescription,
    productCategoryId: isStringEnum(...Object.values(PRODUCT_CATEGORY)),
    recommendationOrder2: isOptional(isNumber),
    imageUrl: isOptional(isString),
    planningFileUrl: isOptional(isString),
});

export const isICharacteristic: SimpleValidator<ICharacteristic> = isObjectWithShape({
    characteristicKey: isStringEnum(...Object.values(CHARACTERISTIC_KEY)),
    productGroupId: isStringEnum(...Object.values(PRODUCT_GROUP)),
});

export const isIProductData: SimpleValidator<IProductData> = isObjectWithShape({
    products: isArrayOf(isIProduct),
    productGroups: isArrayOf(isIProductGroup),
    characteristics: isArrayOf(isICharacteristic),
});
