import { JsonRESTApiClient } from "../api/JsonRESTApiClient";

import { ISettings } from "./types";
import { isISettings } from "./validators";


/** @see `DH.BlueKitConnect.Backend.Features.Settings.SettingsController` */
export class SettingsApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async getSettings(): Promise<ISettings> {
        return await this._fetchJson(
            this._getUrl(),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            isISettings
        );
    }

}

