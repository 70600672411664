import React from 'react';
import { change, Field, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal } from 'semantic-ui-react';

import { TabSection } from '../../../../shared/components/TabSection';
import { renderQuantity } from '../../../../shared/components/FormControls';
import { useDispatchCallback } from '../../../../store/useDispatchCallback';
import { useLocalization } from '../../../../localization/hook';

import { ReduxFormKeys } from '../../../form/ReduxFormKeys';
import { formActionCreator } from '../../../form/FormActions';

import { COST_CALCULATION_FORM_VALUES_SELECTOR } from '../02-ConfigurationInputs/ConfigurationInputsForm';

export interface ICableModalHandle {
    /** Open the modal (with choice to reset to estimatedQuantities) */
    start: (estimatedQuantities: ICustomQuantities) => void;
    /** Whether the quantities have been changed from their estimates */
    changed: boolean;
}

const customQuantitiesKeys = [
    'rj-45-chf-a-4m-custom',
    'rj-45-chf-a-6m-custom',
    'rj-45-chf-a-12m-custom',
    'fm-2m-custom',
] as const;
export type ICustomQuantities = Record<typeof customQuantitiesKeys[number], number>;

const CableModal = React.forwardRef<ICableModalHandle, {}>((_props, ref) => {
    const [isOpen, setOpen] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState(null);
    const [estimatedQuantities, setEstimatedQuantities] = React.useState<ICustomQuantities>(null);

    const costCalculationFormValues = useSelector(getFormValues(ReduxFormKeys.costCalculationForm) as COST_CALCULATION_FORM_VALUES_SELECTOR);

    const dispatch = useDispatch();
    const changeFieldValue = useDispatchCallback(dispatch, (field: string, value: any) => change(ReduxFormKeys.costCalculationForm, field, value))
    const resetFields = useDispatchCallback(dispatch, (fields: readonly string[]) => formActionCreator.resetFields(ReduxFormKeys.costCalculationForm, fields));

    // Event handlers

    const onOpenClick = React.useCallback((estimatedQuantities: ICustomQuantities) => {
        setOpen(true);

        const initialQuantities = Object.fromEntries(customQuantitiesKeys.map(k => [k, costCalculationFormValues[k]]));
        setInitialValues(initialQuantities);
        setEstimatedQuantities(estimatedQuantities);

        // Apply estimated quantities where no custom choice has been made
        if (!estimatedQuantities) return;
        customQuantitiesKeys.forEach(key => {
            if (costCalculationFormValues[key] == null) {
                changeFieldValue(key as any, estimatedQuantities[key]);
            }
        });
    }, [
        setOpen, costCalculationFormValues, setInitialValues, setEstimatedQuantities, changeFieldValue,
    ]);

    const onSaveClick = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onCloseClick = React.useCallback(() => {
        setOpen(false);

        Object.keys(initialValues).forEach(key => {
            changeFieldValue(key, initialValues[key]);
        });
    }, [setOpen, changeFieldValue, initialValues]);

    const onResetClick = React.useCallback(() => {
        setOpen(false);
        resetFields(customQuantitiesKeys);
    }, [setOpen, resetFields]);

    const { localize } = useLocalization();

    // Pass a start function and changed flag to a ref

    React.useImperativeHandle(ref, () => ({
        start: onOpenClick,
        changed: customQuantitiesKeys.map(k => costCalculationFormValues[k]).some(v => v != null),
    }), [onOpenClick, costCalculationFormValues]);

    return (
        <>
            <Modal
                open={isOpen}
                onClose={onCloseClick}
            >
                <Modal.Header>
                    <div className="title">{localize('cableAdjust.title')}</div>
                    <div className="close-button" onClick={onCloseClick}><i aria-hidden="true" className="close icon"></i></div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <TabSection>
                            <Form>
                                <Form.Group inline>
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {estimatedQuantities?.['rj-45-chf-a-4m-custom']})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label="RJ45-CHF-A-4M"
                                        labelPosition="end"
                                        name="rj-45-chf-a-4m-custom"
                                        component={renderQuantity}
                                    />
                                </Form.Group>
                                <Form.Group inline>
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {estimatedQuantities?.['rj-45-chf-a-6m-custom']})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label="RJ45-CHF-A-6M"
                                        labelPosition="end"
                                        name="rj-45-chf-a-6m-custom"
                                        component={renderQuantity}
                                    />
                                </Form.Group>
                                <Form.Group inline>
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {estimatedQuantities?.['rj-45-chf-a-12m-custom']})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label="RJ45-CHF-A-12M"
                                        labelPosition="end"
                                        name="rj-45-chf-a-12m-custom"
                                        component={renderQuantity}
                                    />
                                </Form.Group>
                                <Form.Group inline className="no-margin-bottom">
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {estimatedQuantities?.['fm-2m-custom']})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label={localize('Function Module FM-2M')}
                                        labelPosition="end"
                                        name="fm-2m-custom"
                                        component={renderQuantity}
                                    />
                                </Form.Group>
                            </Form>
                        </TabSection>
                        <br/>
                        <div className="btn-group centered">
                            <Button primary onClick={onSaveClick}>
                                {localize('Apply')}
                            </Button>
                            <Button secondary onClick={onResetClick}>
                                {localize('Reset')}
                            </Button>
                        </div>

                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
});

export default CableModal;
