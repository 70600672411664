import { JsonRESTApiClient } from "../api/JsonRESTApiClient";

import { IConfig } from "./types";
import { isIConfig } from "./validators";


/** @see `DH.BlueKitConnect.Backend.Features.Config.ConfigController` */
export class ConfigApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async getConfig(): Promise<IConfig> {
        return await this._fetchJson(
            this._getUrl(),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            isIConfig
        );
    }

}
