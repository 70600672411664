import { JsonRESTApiClient } from "../api/JsonRESTApiClient";

import { IUser } from "./types";
import { isIUser } from "./validators";


/** @see `DH.BlueKitConnect.Backend.Features.User.UserController` */
export class UserApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async current(): Promise<IUser> {
        return await this._fetchJson(
            this._getUrl(['current']),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            isIUser
        );
    }

}
