import * as React from 'react';
import { useState } from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';

import { ICableModalHandle, ICustomQuantities } from './_CableModal';
import { ICostPosition, ISelectedProduct } from '../../CalculationModels';
import { FormatMontage, FormatPrice } from './_CostCalculationList';
import { PRODUCT_KEY } from '../../../product/lookup';
import { useLocalization, useProductLanguage } from '../../../../localization/hook';

export type ITableRowAccordionProps = {
    visible: boolean;
    title: string;
    className?: string;
    costs: ICostPosition;
    noShowMontage?: boolean;
    cableModalHandle?: ICableModalHandle;
};

export const TableRowAccordion: React.FC<ITableRowAccordionProps> = props => {

    const { localize } = useLocalization();
    const productLanguage = useProductLanguage();

    const rowClassName = "table-row-accordion " + props.className;
    const rowItemClassName = "table-row-item-accordion " + props.className;
    const hasProducts = props.costs?.products?.filter(x => x.quantity > 0).length > 0;

    const estimatedQuantities = React.useMemo(() => {
        return {
            'rj-45-chf-a-4m-custom':  getEstimatedQuantity(props.costs?.products, 'cable4m'),
            'rj-45-chf-a-6m-custom':  getEstimatedQuantity(props.costs?.products, 'cable6m'),
            'rj-45-chf-a-12m-custom': getEstimatedQuantity(props.costs?.products, 'cable12m'),
            'fm-2m-custom':           getEstimatedQuantity(props.costs?.products, 'fm-2m'),
        } as ICustomQuantities;
    }, [props.costs?.products]);

    // Expand category
    const [isVisible, setVisible] = useState(props.visible);
    const onClickToggleVisibility = React.useCallback(() => setVisible(!isVisible), [isVisible, setVisible]);

    return (
        <>
            <Table.Row className={rowClassName}>
                <Table.Cell colSpan="3" verticalAlign="top" className="no-padding">
                    <div className="title">
                        <span dangerouslySetInnerHTML={{__html: props.title}}/>
                        {
                            props.cableModalHandle &&
                            <span>
                                {' - '}
                                <a onClick={() => props.cableModalHandle.start(estimatedQuantities)}>
                                    {localize(props.cableModalHandle.changed ? 'cableAdjust.button.adjusted' : 'cableAdjust.button.default')}
                                </a>
                            </span>
                        }
                    </div>
                </Table.Cell>
                <Table.Cell className="price-cell" textAlign="right" verticalAlign="top">
                    <FormatPrice pos={props.costs} />
                </Table.Cell>
                <Table.Cell className="price-cell" textAlign="right" verticalAlign="top">
                    <FormatMontage pos={{ montage: props.noShowMontage ? null : props.costs.montage }} />
                </Table.Cell>
                <Table.Cell textAlign="right" verticalAlign="top">
                    {
                        hasProducts &&
                        <Label as="a" className="accordionLabel" onClick={onClickToggleVisibility}>
                            {isVisible && hasProducts &&
                                <>{localize('accordion.reduce')}<Icon name="chevron up"/></>
                            }
                            {!isVisible && hasProducts &&
                                <>{localize('accordion.expand')}<Icon name="chevron down"/></>
                            }
                        </Label>
                    }
                </Table.Cell>
            </Table.Row>

            {isVisible && hasProducts && (
                <>
                    <Table.Row className={rowItemClassName}>
                        {['quantity', 'description', 'dhNo'].map(key => (
                            <Table.Cell className={'no-border ' + key} key={key}>{localize('costCalculation.table.accordion.column.' + key)}</Table.Cell>
                        ))}
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                    </Table.Row>
                    {
                        props.costs?.products?.filter(x => x.quantity > 0).map(product =>
                            <Table.Row className={rowItemClassName} key={product.id + '-' + product.description[productLanguage]}>
                                <Table.Cell className="no-border" verticalAlign="top">{product.quantity}</Table.Cell>
                                <Table.Cell className="no-border" verticalAlign="top">{product.description[productLanguage]}</Table.Cell>
                                <Table.Cell className="no-border" verticalAlign="top">{product.dhNo}</Table.Cell>
                                <Table.Cell className="price-cell" verticalAlign="top" textAlign="right">
                                    <FormatPrice pos={product} />
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        )
                    }
                </>
            )}
        </>
    );
};

function getEstimatedQuantity(products: ISelectedProduct[] | undefined, key: PRODUCT_KEY | string) {
    if (!products || products.length === 0) return 0;

    const list = products.filter(p => p.productKey === key);
    return list.length > 0
        ? list[0].estimatedQuantity
        : 0;
}
