/** This file is a library of simple validation functions for Javascript values, producing Typescript type hints.
 * 
 * The origin of this library lies in the various API clients based on JsonRESTApiClient.
 * The included functions aid in the creation of functions suitable for
 * the `validator` argument of `protected JsonRESTApiClient._fetchJson()`.
 * 
 * This uses zero dependencies outside of the Javascript `===` and `typeof` operators, and runtime functions:
 * - `Array.isArray`
 * - `Array.prototype.every`
 * - `Object.assign`
 * - `Object.entries`
 * - `Object.prototype.hasOwnProperty`
 */

type ValidatorFn<T> = (value: unknown) => value is T;
export type SimpleValidator<T> = ValidatorFn<T> & { validatorName: string; nothrow: ValidatorFn<T>; };

export class ValidationError extends Error {
    constructor(public readonly path: PropertyKey[], private readonly innerMessage?: string) { super(`${path?.join('.')}: ${innerMessage}`) }
    static fromNested(path: PropertyKey[], nested: Error) {
        return new ValidationError(
            [...(path ?? []), ...(nested instanceof ValidationError ? nested.path ?? [] : [])],
            (nested instanceof ValidationError ? nested.innerMessage : `${nested.name}: ${nested.message}`),
        );
    }
}

/** Add `validatorName` and `nothrow()` properties to a `ValidatorFn` */
function enrichValidator<T>(validatorName: string, validator: ValidatorFn<T>): SimpleValidator<T> {

    const wrapped: ValidatorFn<T> = (value): value is T => {
        if (validator(value)) return true;
        else throw new ValidationError(null, validatorName);
    }

    const nothrow: ValidatorFn<T> = (value): value is T => {
        try {
            return validator(value);
        } catch (e) {
            return false;
        }
    };

    return Object.assign(wrapped, { validatorName, nothrow });
}

/** Checks that `value` is an array and that every element passes validation by `elementValidator`
 * @example ```
 * const v: unknown = ...;
 * 
 * if (isArrayOf(isNumber)(v)) {
 *     // Typescript hint: v is number[]
 * }
 * ```
 */
export function isArrayOf<T>(elementValidator: SimpleValidator<T>): SimpleValidator<T[]> {
    return enrichValidator("isArrayOf", (value): value is T[] => Array.isArray(value) && value.every((element, idx) => {
        try {
            return elementValidator(element);
        } catch (e) {
            throw ValidationError.fromNested([idx], e);
        }
    }));
}

/** Checks that `value` is a tuple with elements matching the respective validators
 * @example ```
 * const v: unknown = ...;
 * 
 * if (isTuple([isString, isNumber])(v)) {
 *     // Typescript hint: v is [string, number]
 * }
 * ```
 */
export function isTuple<T extends any[]>(validators: { [K in keyof T]: SimpleValidator<T[K]> }): SimpleValidator<{ [K in keyof T]: T[K] }> {
    return enrichValidator("isTuple", (value): value is { [K in keyof T]: T[K] } => Array.isArray(value) && value.length === validators.length && value.every((v, idx) => {
        try {
            return validators[idx](v);
        } catch (e) {
            throw ValidationError.fromNested([idx], e);
        }
    }));
}

/** Checks that `value` is an object and that it has all the properties on keys `keys`
 * @deprecated Prefer `isObjectWithShape` instead
 * @example ```
 * const v: unknown = ...;
 * 
 * if (isObjectWithProperties('id', 'name')(v)) {
 *     // Typescript hint: v is { id: any; name: any; }
 * }
 * ```
 */
export function isObjectWithProperties<KeysT extends PropertyKey>(...keys: KeysT[]): SimpleValidator<{ [x in KeysT]: any; }> {
    return enrichValidator("isObjectWithProperties", (value): value is { [x in KeysT]: any; } => (
        !!value &&
        typeof value === 'object' &&
        keys.every(key => {
            try {
                return Object.prototype.hasOwnProperty.call(value, key);
            } catch (e) {
                throw ValidationError.fromNested([key], e);
            }
        })
    ));
}

/** Checks that `value` is an object with a properties matching the provided property validators
 * 
 * @example ```
 * const v: unknown = ...;
 * 
 * if (isObjectWithShape({ id: isNumber, name: isString })(v)) {
 *     // Typescript hint: v is { id: number; name: string; }
 * }
* ```
 */
export function isObjectWithShape<ShapeT extends object>(validators: { [x in keyof ShapeT]-?: SimpleValidator<ShapeT[x]>; }): SimpleValidator<ShapeT> {
    return enrichValidator("isObjectWithShape", (value: unknown): value is ShapeT => {
        if (isNoValue.nothrow(value) || typeof value !== 'object') return false;
        return Object.entries(validators).every(([key, validator]) => {
            let propertyValue = undefined;
            if (Object.prototype.hasOwnProperty.call(value, key)) propertyValue = value[key];
            try {
                return (validator as ValidatorFn<any>)(propertyValue);
            } catch (e) {
                throw ValidationError.fromNested([key], e);
            }
        });
    });
}

/** Passes everything */
export const isUnknown = enrichValidator("isUnknown", (value: unknown): value is unknown => true);

/** Checks that `value` is either null or undefined */
export const isNoValue = enrichValidator("isNoValue", (value: unknown): value is undefined|null => value === undefined || value === null);

export const isString = enrichValidator("isString", (value: unknown): value is string => !isNoValue.nothrow(value) && typeof value === 'string');
export const isNumber = enrichValidator("isNumber", (value: unknown): value is number => !isNoValue.nothrow(value) && typeof value === 'number');
export const isBoolean = enrichValidator("isBoolean", (value: unknown): value is boolean => !isNoValue.nothrow(value) && typeof value === 'boolean');

export function isStringEnum<ValuesT extends string>(...values: ValuesT[]) : SimpleValidator<ValuesT> {
    return enrichValidator("isStringEnum", (value): value is ValuesT => isString(value) && values.includes(value as any));
}

export function isOptional<T>(validator: (e: unknown) => e is T): SimpleValidator<T|null|undefined> {
    return enrichValidator("isOptional", (value): value is T|null|undefined => isNoValue.nothrow(value) || validator(value));
}

export function isEither<T1, T2>(validator1: SimpleValidator<T1>, validator2: ValidatorFn<T2>): SimpleValidator<T1 | T2> {
    return enrichValidator("isEither", (value): value is T1 | T2 => validator1.nothrow(value) || validator2(value));
}

export function isBoth<T1, T2>(validator1: ValidatorFn<T1>, validator2: ValidatorFn<T2>): SimpleValidator<T1 & T2> {
    return enrichValidator("isBoth", (value): value is T1 & T2 => validator1(value) && validator2(value));
}
