import * as React from "react";

import { useConfig } from "../../configProvider";
import { useFetchOnce } from "../../shared/useFetchOnce.hook";

import { UserApiV1Client } from "./UserApiV1Client";
import { IUser } from "./types";
import { useAuthTokenContext } from "./AuthService";


interface UserContextType {
    user: IUser|null;
    userLoading: boolean;
    userError: Error|null,
    fetchUser: () => void;
    resetUser: () => void;
}
const UserContext = React.createContext<UserContextType>(null);

export const UserContextProvider: React.FC<{}> = ({ children }) => {
    const { apiUrl } = useConfig();
    const { getToken } = useAuthTokenContext();
    const client = React.useMemo(() => new UserApiV1Client(apiUrl['user-v1'], getToken), [apiUrl, getToken]);
    const userGetter = React.useCallback(() => client.current(), [client]);

    const {
        value: user,
        loading: userLoading,
        error: userError,
        fetch: fetchUser,
        reset: resetUser,
    } = useFetchOnce(userGetter);

    const value = React.useMemo(() => ({
        user,
        userLoading,
        userError,
        fetchUser,
        resetUser,
    }), [
        user,
        userLoading,
        userError,
        fetchUser,
        resetUser,
    ]);

    return React.createElement(UserContext.Provider, { value }, children);
};

export function useUser() {
    return React.useContext(UserContext);
}
