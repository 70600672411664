import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { isEither, isObjectWithShape, isUnknown, SimpleValidator } from "../api/simple-validators";
import { ICalculationInput, ICosts } from "./CalculationModels";


/** @see `DH.BlueKitConnect.Backend.Features.Calculation.CalculationController` */
export class CalculationApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async calculateCosts(calculationInput: ICalculationInput): Promise<ICosts> {
        return await this._fetchJson(
            this._getUrl(['calculate-costs']),
            {
                method: 'POST',
                headers: await this._getHeaders(),
                body: JSON.stringify(calculationInput),
            },
            isICosts
        );
    }

}

// @TODO: A more precise validation
const isICosts: SimpleValidator<ICosts> = isEither(
    isObjectWithShape({ blueKitSystemControlComponents: isUnknown }),
    isObjectWithShape({ aioBasicSet: isUnknown })
) as SimpleValidator<ICosts>;
